const BasicQuantitySelector = (() => {

	class Core {

	    constructor({element, min, max, step}) {
	        
	        this.$el = element;
            this.$config = {
                min:  parseInt(min),
                max:  parseInt(max),
                step: parseInt(step)
            }
            
            this.build();
            this.events();
	        		    	
	    }


        build() {

            let min = this.$el.value <= this.$config.min;
            let max = this.$el.value >= this.$config.max;


            this.$parent = document.createElement('div');
            this.$parent.classList.add('quantity-selector');
            this.$parent.innerHTML = `
                <button type="button" class="quantity-selector__trigger --dec" ${min ? 'disabled' : ''} aria-label="decrease">-</button>
                <div class="quantity-selector__content"></div>
                <button type="button" class="quantity-selector__trigger --inc" ${max ? 'disabled' : ''} aria-label="increase">+</button>
            `;
            

            if (min) this.$el.value = this.$config.min;
            if (max) this.$el.value = this.$config.max;

            this.$el.classList.add('quantity-selector__field');
            this.$el.insertAdjacentHTML('afterend', this.$parent.outerHTML);

            this.$parent = this.$el.nextElementSibling;
            this.$parent.querySelector('.quantity-selector__content').appendChild(this.$el);

            this.$triggers = {
                dec: this.$parent.querySelector('.quantity-selector__trigger.--dec'),
                inc: this.$parent.querySelector('.quantity-selector__trigger.--inc')
            }

        }


        events() {

            this.$parent.addEventListener('input', this.listener_input.bind(this), false);
            this.$parent.addEventListener('click', this.listener_click.bind(this), false);

        }


        listener_input() {

            let statusMin = (this.$el.value < this.$config.min),
                statusMax = (this.$el.value >= this.$config.max);

                
            if (statusMin) this.$el.value = this.$config.min;					
            if (statusMax) this.$el.value = this.$config.max;	

            this.$triggers.dec.disabled = (this.$el.value <= this.$config.min);
            this.$triggers.inc.disabled = (this.$el.value >= this.$config.max);

        }


        listener_click(event) {

            let target = event.target,
                statusMin = (this.$el.value > this.$config.min),
                statusMax = (this.$el.value < this.$config.max);


            this.$el.value = (this.$el.value == '') ? this.$config.min : this.$el.value;    

            
            if (target.classList.contains('--dec') == true) {

                this.$el.value = statusMin ? (parseInt(this.$el.value) - this.$config.step) : this.$config.min;

            }

            if (target.classList.contains('--inc') == true) {

                this.$el.value = statusMax ? (parseInt(this.$el.value) + this.$config.step) : this.$config.max;

            }


            this.$triggers.dec.disabled = !(this.$el.value > this.$config.min);
            this.$triggers.inc.disabled = !(this.$el.value < this.$config.max);

        }

	}


    let active = null;


	// init
	const init = (variable) => {

		let config = {},
            elements = BasicCore.variables(variable, '.js-quantity-selector');		

            			
		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);

            elements.forEach((value) => {

                config.element = value;
                config.step = value.getAttribute('step') || 1;
                config.min = value.getAttribute('min')   || 0;
                config.max = value.getAttribute('max')   || 999999999;

                active = new Core(config);            

            });

            return active;
        
        } catch(error) {

			console.error(`${BasicCore.logging['name']} Quantity Selector init. \nMessage: ${error.message} \nElement: `, variable);

		}    

	}   


	return { init };

})()


window.BasicQuantitySelector = BasicQuantitySelector;


export  { BasicQuantitySelector };
// --- /Quantity selector ---