/**
 * Basic UI: Lazy loading img.
 */
const BasicLazyLoadingImg = (() => {

	class Core {

	    constructor(elements) {
	        
	        this.$elems = elements;


			if ('loading' in HTMLImageElement.prototype) { 
			    
			    this.$elems.forEach(value => {

			    	let dataLazySrc = value.getAttribute('data-lazy-src'); 
					
			    	value.setAttribute('src', dataLazySrc);
			    	value.removeAttribute('data-lazy-src'); 
			    	value.onload = () => value.classList.add('loaded');
			    
			    });

			} else {

				this.lazy_loading();
				window.addEventListener('scroll', this.lazy_loading.bind(this), false);
				
			}
	        		    	
	    }


		lazy_loading(event) {
			
			this.$elems.forEach((value, index, array) => {

				if (window.outerHeight > Math.abs(value.getBoundingClientRect().top) && value.hasAttribute('src') == false) {

					let lazy = value.getAttribute('data-lazy-src');
					
					value.setAttribute('src', lazy);
					value.removeAttribute('data-lazy-src');
					value.onload = () => value.classList.add('loaded');

					array.splice(index, 1);

				}				

				
				// если изображений с ленивой загрузкой больше на странице нет, то остановим обработчик.
				if(array.length == 0) event.stopPropagation();

			})

		}
	
	}


	let active = null;


	/**
	 * Lazy loading images initialization
	 * @param {Object|string} variable - element for initializing lazy loading
	 */
	const init = (variable) => {

		let elements = Array.from(document.querySelectorAll(variable || '[data-lazy-src]'));			
		
		active = new Core(elements);
		return active;

	}   


	return { init };

})()


window.BasicLazyLoadingImg = BasicLazyLoadingImg;


export { BasicLazyLoadingImg };