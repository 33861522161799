const BasicCheckbox = (() => {

    class Core {

        constructor(element) {
            
            this.$el = element;

            this.variables();
            this.build();

        }


        variables() {
                                
            let listInit = Array.from(document.querySelectorAll('.checkbox-init'));
            let dataOptions = JSON.parse(this.$el.getAttribute('data-checkbox-options'))  || {};
            let dataClassNames = JSON.parse(this.$el.getAttribute('data-checkbox-class')) || {};
            
            listInit.push(this.$el);
            
            this.$options = {
                index:     listInit.indexOf(this.$el),
                type:      dataOptions['type']    || 'default',
                content:   dataOptions['content'] || '',
                className: {
                    label:   dataClassNames['label']   || 'checkbox-label',
                    field:   dataClassNames['field']   || 'checkbox-field',
                    replace: dataClassNames['replace'] || 'checkbox-replace',
                }
            }; 

        }


        build() {

            let elementLabel = document.createElement('label'),
			    elementSpan = document.createElement('span');

            
            this.$el.classList.add('checkbox-init');
            this.$el.classList.add(this.$options.className.field);
            this.$el.id = `checkbox-id-${this.$options.index}`;

            elementSpan.classList.add(this.$options.className.replace);            
		
            elementLabel.classList.add(this.$options.className.label);
            elementLabel.classList.add(`checkbox--${this.$options.type}`);
            elementLabel.setAttribute('for', `checkbox-id-${this.$options.index}`);			
            elementLabel.innerHTML = this.$el.outerHTML + elementSpan.outerHTML + this.$options.content;

            this.$el.parentNode.replaceChild(elementLabel, this.$el);

        }

    }


    let active = null;


	/**
	 * Checkbox initialization
	 * @param {Object|string} variable - element for initializing checkbox 
	 */
    const init = (variable) => {
		
		let element = null,
			elements = BasicCore.variables(variable, '.js-custom-checkbox:not(.checkbox-init)');

			
		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
						
			elements.forEach((value) => {

				element = value;				
				active = new Core(value)

			});
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Checkbox init. \nMessage: ${error.message} \nElement: `, element);

		}
    }


    return { init };

})()


window.BasicCheckbox = BasicCheckbox;


export { BasicCheckbox };