/**
 * Basic UI: Toggle visible content.
 */
const BasicToggleVisibleContent = (() => {

	class Core {

	    constructor(element) {
	        
	        this.$el = element;
			this.$trigger = this.$el.querySelector('.toggle-visible-trigger') || null; 
	        this.$content = this.$el.querySelector('.toggle-visible-content') || null;

			this.$options = {
				className:  {
					show: 'toggle-visible--show'
				}
			};
			
			this.$customEvent = new CustomEvent('toggleVisible switched', { 
				bubbles: true,
				detail:  {
					'element': null,
					'hidden': true
				}
			});						
	        		    	
	    }


		init() {

			this.build();
			this.$trigger.addEventListener('click', this.listener.bind(this), false);
		
		}



		build() {

			// show content
			if (this.$trigger !== null && this.$trigger.getAttribute('aria-expanded') == "true") {

				this.method_show();
			
			}
			
			this.$el.classList.add('toggle-visible');

		}


	    listener(event) {

			let active = false;

			
			this.$el.classList.toggle(this.$options.className.show);
			active = this.$el.classList.contains(this.$options.className.show);
			
			this.$trigger.setAttribute('aria-expanded', active);			
			
			this.$content.style.height = `${this.$content.scrollHeight}px`;
			setTimeout(() => this.$content.style.height = (active) ? 'auto' : '', 150);
			

			// custom event: toggle visible switched
			this.$customEvent.detail.element = this.$el;
			this.$customEvent.detail.hidden = !active;
			this.$el.dispatchEvent(this.$customEvent);			
		
		}


		method_show() {
			
			this.$trigger.setAttribute('aria-expanded', true);
			this.$el.classList.add(this.$options.className.show);
			
			this.$content.style.height = `${this.$content.scrollHeight}px`;
			setTimeout(() => this.$content.style.height = 'auto', 150);

		}


		method_hide() {

			this.$trigger.setAttribute('aria-expanded', false);			
			this.$el.classList.remove(this.$options.className.show);
			
			this.$content.style.height = `${this.$content.scrollHeight}px`;
			setTimeout(() => this.$content.style.height = '', 150);
		
		}

	}


	let active = null;


	/**
	 * Toggle Visible initialization
	 * @param {object|string} variable - element for initializing toggle visible 
	 */
	const init = (variable) => {

		let element = null,
			elements = BasicCore.variables(variable, '.js-toggle-visible');


		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);

			elements.forEach((value) => {
				
				element = value;

				active = new Core(value);
				active.init();
			
			});
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Toggle Visible init. \nMessage: ${error.message} \nElement: `, element);

		}
		
	}
	
	
	/**
	 * Toggle show
	 * @param {object|string} variable - element show
	 */
	const show = (variable) => {

		let element = (typeof variable == 'string') ? document.querySelector(variable) : variable;


		try {

			if (element == null ) throw new Error(BasicCore.logging['error']['type']);

			active = new Core(element);
			active.method_show();
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Toggle Visible show. \nMessage: ${error.message} \nElement:`, variable);

		}

	}
	
	
	/**
	 * Toggle hide
	 * @param {object|string} variable - element hide
	 */
	const hide = (variable) => {

		let element = (typeof variable == 'string') ? document.querySelector(variable) : variable;

		
		try {

			if (element == null ) throw new Error(BasicCore.logging['error']['type']);

			active = new Core(element);
			active.method_hide();
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Toggle Visible hide. \nMessage: ${error.message} \nElement:`, variable);

		}

	}


	return { init, show, hide };

})()


window.BasicToggleVisibleContent = BasicToggleVisibleContent;


export { BasicToggleVisibleContent };