export class Core {

    constructor(element) {
                    
        this.$el = element;
        this.$children = Array.from(this.$el.options);

        let arrayInit = Array.from(document.querySelectorAll('.select-init'));
        let dataOptions = JSON.parse(this.$el.getAttribute('data-select-options')) || {};
        let dataClassNames =  JSON.parse(this.$el.getAttribute('data-select-class')) || {};; 

        arrayInit.push(this.$el);

        this.$options = {
            value: '',
            index: arrayInit.indexOf(this.$el),
            type: (this.$el.getAttribute('multiple') !== null) ? 'multiple' : 'default',
            enabledSearch: dataOptions['enabledSearch'] || true,
            notfound: dataOptions['notfound'] 			|| 'ничего не найдено',

            placeholder: {
                output: dataOptions['placeholderOutput'] || 'Выберите из списка',
                search: dataOptions['placeholderSearch'] || 'Найти'
            },

            classNames: {
                select: {
                    main: 	dataClassNames['main']   || 'select',
                    open:   dataClassNames['open']   || 'select--open',
                    placehoder: 'select-view--placeholder',
                    selected: 	'select-view--selected',
                    notfound: 	'select-view--notfound',
                    top: 	'select--top',
                    bottom: 'select--bottom'
                },

                header:   dataClassNames['header'] || 'select-header',
                output:   {
                    main:  dataClassNames['output']      || 'select-output',
                    item:  dataClassNames['outputItem']  || 'select-output__item',
                    clear: dataClassNames['outputClear'] || 'select-output__clear'
                },
                clear:    dataClassNames['clear']    || 'select-clear',
                dropdown: dataClassNames['dropdown'] || 'select-dropdown',
                                    
                search: {
                    wrapper: dataClassNames['searchWrapper'] || 'select-search',
                    field:   dataClassNames['searchField'] 	 || 'select-search__field',
                },
                notfound: dataClassNames['notfound'] || 'select-notfound',

                listbox:  dataClassNames['listbox']  || 'select-listbox',					
                option: {
                    main: 		dataClassNames['option'] || 'select-option',
                    placehoder: 'select--placeholder',
                    selected: 	'select--selected',
                    disabled:	'select--disabled',
                    hide:		'select--hide',
                    show:		'select--show'
                }
            }
        };

        this.funcClickByArea = (event) => {

            if (event.target.closest(`.${this.$options.classNames.select.main}`) == null) {
                
                this.$units.select.classList.remove(this.$options.classNames.select.open);
                this.$units.header.setAttribute('aria-expanded', false);
                document.removeEventListener('click', this.funcClickByArea, false);

            }

        }

        this.$customEventChoice = new CustomEvent('select choice', { 
            bubbles: true,
            detail:  {
                'el': null,
                'index': null,
                'value': null,
                'target': null,
                'previous': null,
                'select': null,
                'selected': null,
            }
        });


        this.$customEventClear = new CustomEvent('select clear', { 
            bubbles: true,
            detail:  {
                'index': null,
                'value': null,
                'previous': null,
                'select': null,
                'selected': null,
            }
        });


        this.$customEventVisibility = new CustomEvent('select visibility', { 
            bubbles: true,
            detail:  {
                'index': null,
                'select': null,
                'visibility': false,
            }
        });

        
        this.build();
        this.events();        
        
    }


    events() {

        // search
        if (this.$units.search !== false) {

            this.$units.search.addEventListener('input', this.listener_search.bind(this), false);
        
        }

    }


    build() {

        this.$units = {
            select:   document.createElement('div'),
            header:   document.createElement('div'),
            clear:	  document.createElement('button'),
            output:   document.createElement('div'),
            dropdown: document.createElement('div'),
            search:	  document.createElement('div'),
            listbox:  document.createElement('div'),
            notfound: document.createElement('span')
        };


        this.$units.select.classList.add(this.$options.classNames.select.main);
        this.$units.select.classList.add(`select--${this.$options.type}`);
        this.$units.select.classList.add(this.$options.classNames.select.placehoder);        
        this.$units.select.setAttribute('data-select-value', this.$options.placeholder.output);


        // build units
        this.build_header();
        this.build_search();
        this.build_notfound();
        this.build_options();
        this.build_dropdown();
        

        // add header and dropdown
        this.$units.select.innerHTML = this.$units.header.outerHTML + this.$units.dropdown.outerHTML;
        
        
        // result
        this.$el.tabIndex = -1;
        this.$el.classList.add('select-init');			
        this.$el.classList.add('select--hidden');			
        this.$el.insertAdjacentHTML('afterend', this.$units.select.outerHTML);
        
        
        // rewrite $units
        this.$units.select =   this.$el.nextElementSibling;		
        this.$units.header =   this.$units.select.querySelector(`.${this.$options.classNames.header}`);
        this.$units.output =   this.$units.select.querySelector(`.${this.$options.classNames.output.main}`);
        this.$units.clear =    (this.$options.type == 'multiple') ? null : this.$units.select.querySelector(`.${this.$options.classNames.clear}`);
        this.$units.dropdown = this.$units.select.querySelector(`.${this.$options.classNames.dropdown}`);
        this.$units.search =   this.$units.select.querySelector(`.${this.$options.classNames.search.field}`) || false;
        this.$units.listbox =  this.$units.select.querySelector(`.${this.$options.classNames.listbox}`);
        this.$units.options =  Array.from(this.$units.listbox.querySelectorAll(`.${this.$options.classNames.option.main}`));
        
        if (this.$options.type == 'multiple') {

            this.$units.optionsSelected = Array.from(this.$units.select.querySelectorAll(`.${this.$options.classNames.option.selected}`)) || []

        } else {

            this.$units.optionsSelected = this.$units.select.querySelector(`.${this.$options.classNames.option.selected}`) || null;

        }

    }


    build_header() {

        // output
        this.$units.output.classList.add(this.$options.classNames.output.main);
        this.$units.output.innerHTML = this.$options.placeholder.output;

        //clear
        this.$units.clear.classList.add(this.$options.classNames.clear);
        this.$units.clear.setAttribute('type', 'button');
        this.$units.clear.setAttribute('title', 'Clear selection');
        this.$units.clear.setAttribute('aria-label', 'Clear selection');
        this.$units.clear.textContent = 'X';

        let resultClear = (this.$options.type == 'multiple') ? '' : this.$units.clear.outerHTML;

        // header
        this.$units.header.classList.add(this.$options.classNames.header);
        this.$units.header.setAttribute('tabindex', 0);
        this.$units.header.setAttribute('role', 'combox');
        this.$units.header.setAttribute('aria-expanded', false);
        this.$units.header.setAttribute('aria-haspopup', 'listbox');
        this.$units.header.setAttribute('aria-label', this.$options.placeholder.output);
        this.$units.header.setAttribute('aria-controls', `select-${this.$options.index}-dropdown`);
        this.$units.header.innerHTML = this.$units.output.outerHTML + resultClear;
        
        this.$units.output = this.$units.header.querySelector(`.${this.$options.classNames.output.main}`);

    }


    build_search() {

        if (this.$options.enabledSearch == true) {

            this.$units.search.classList.add(this.$options.classNames.search.wrapper);
            this.$units.search.innerHTML = `
                <input 
                    class="${this.$options.classNames.search.field}" 
                    type="search" 
                    placeholder="${this.$options.placeholder.search}"
                >
            `;

            this.$units.dropdown.innerHTML = this.$units.search.outerHTML;
        
        }

    }


    build_notfound() {

        this.$units.notfound.classList.add(this.$options.classNames.notfound);
        this.$units.notfound.classList.add('select--notfound');
        this.$units.notfound.innerHTML = this.$options.notfound;

    }


    build_dropdown() {

        this.$units.listbox.classList.add(this.$options.classNames.listbox);
        
        this.$units.dropdown.classList.add(this.$options.classNames.dropdown);
        this.$units.dropdown.setAttribute('role', 'listbox')
        this.$units.dropdown.setAttribute('id', `select-${this.$options.index}-dropdown`);
        this.$units.dropdown.innerHTML += this.$units.listbox.outerHTML;

    }


    build_options() {

        let elementOption = null;
        let elementOutputItem = null;

        let params = {
            selected: {
                value: [],
                output: [],
            } 
        };
                
        
        this.$children.forEach((variable) => {
            
            elementOption = document.createElement('div');				
            elementOption.classList.add(this.$options.classNames.option.main);
            elementOption.setAttribute('role', 'option');
            elementOption.setAttribute('aria-selected', false);
            elementOption.setAttribute('data-option-value', variable.value);
            elementOption.innerHTML = variable.getAttribute('data-select-label') || variable.textContent;
            
            
            // added data-attrs
            Object.entries(Object.assign({}, variable.dataset)).forEach((value) => {
                
                if (value[0] !== 'selectLabel') {

                    elementOption.dataset[value[0]] = value[1] 

                }

            })


            // placeholder
            if (variable.getAttribute('placeholder') !== null) {

                elementOption.classList.add(this.$options.classNames.option.placehoder);
                
                this.$units.select.classList.add(this.$options.classNames.select.placehoder);
                this.$units.select.setAttribute('data-select-value', '');
                this.$units.header.setAttribute('aria-label', variable.value);
                this.$units.output.innerHTML = variable.getAttribute('data-select-label') || variable.textContent;

                this.$options.value = variable.value;
                this.$options.placeholder.output = variable.value;

            }


            // selected
            if (variable.getAttribute('selected') !== null && variable.getAttribute('disabled') == null) {
                
                if (this.$options.type == 'multiple') {
                    
                    elementOutputItem = document.createElement('div');
                    elementOutputItem.classList.add(this.$options.classNames.output.item);
                    elementOutputItem.setAttribute('data-item-value', variable.textContent);
                    elementOutputItem.innerHTML = `
                        <span>${variable.getAttribute('data-select-label') || variable.textContent}</span>
                        <button 
                            type="button" 
                            class="${this.$options.classNames.output.clear}" 
                            aria-label="Delete selected"
                            title="Delete selected"
                        >X</button>
                    `;

                    params.selected.output.push(elementOutputItem.outerHTML);

                } else {

                    params.selected.output.push(variable.getAttribute('data-select-label') || variable.textContent);

                }

                elementOption.classList.add(this.$options.classNames.option.selected);
                elementOption.setAttribute('aria-selected', true);

                this.$units.select.classList.remove(this.$options.classNames.select.placehoder);
                this.$units.select.classList.add(this.$options.classNames.select.selected);
                
                params.selected.value.push(variable.value);
                this.$units.select.setAttribute('data-select-value', params.selected.value.join());
                this.$units.output.innerHTML = params.selected.output.join(' ');
                this.$options.value = params.selected.value.join();
                
            }


            // disabled
            if (variable.getAttribute('disabled') !== null) {

                elementOption.classList.add(this.$options.classNames.option.disabled);

            }


            this.$units.listbox.innerHTML += elementOption.outerHTML;


            // remove;
            if (variable.getAttribute('placeholder') == null && variable.getAttribute('selected') == null) {

                variable.remove();

            }

        })


        params.selected.value = [];
        params.selected.value = [];

    }

    
    listener_search(event) {

        let status = true,
            output = this.$units.search.value.toLowerCase(),
            notfound = this.$units.select.querySelector(`.${this.$options.classNames.notfound}`) || false;				

            
        // show/hide options
        this.$units.options.forEach((variable) => {

            let value = variable.getAttribute('data-option-value').toLowerCase();

            if (value.search(output) == 0)  {

                variable.classList.remove(this.$options.classNames.option.hide);

            } else {

                variable.classList.add(this.$options.classNames.option.hide);
            
            }

        });


        status = this.$units.options.find((value) => value.classList.contains(this.$options.classNames.option.hide) == false) || false;
        

        // "not found"
        if (status == false)  {

            this.$units.listbox.appendChild(this.$units.notfound);
            this.$units.select.classList.add(this.$options.classNames.select.notfound);				
            
        } else {
                            
            if (notfound !== false) notfound.remove();
            this.$units.select.classList.remove(this.$options.classNames.select.notfound);
            
        }

    }


    dropdown_position() {

        this.$units.dropdown.style.top = '';
        this.$units.select.classList.remove(this.$options.classNames.select.top);
        this.$units.select.classList.remove(this.$options.classNames.select.bottom);


        if (this.$units.dropdown.getBoundingClientRect().bottom > window.innerHeight) {

            this.$units.select.classList.add(this.$options.classNames.select.top);
            this.$units.dropdown.style.top = `-${this.$units.dropdown.getBoundingClientRect().height}px`;	

        } else {
            
            this.$units.select.classList.add(this.$options.classNames.select.bottom);
            this.$units.dropdown.style.top = `${this.$units.header.getBoundingClientRect().height}px`;

        }		

    }


    dropdowwn_visibility() {
        
        this.$units.select.classList.toggle(this.$options.classNames.select.open);
        
        let status = this.$units.select.classList.contains(this.$options.classNames.select.open);
                
        this.$units.header.setAttribute('aria-expanded', status);
        this.dropdown_position();

        // click outside the element
        if (status == true) {

            document.addEventListener('click', this.funcClickByArea, false);

        }

        // custom event: visibility
        this.$customEventVisibility.detail.el = this.$el;
        this.$customEventVisibility.detail.index = this.$options.index;
        this.$customEventVisibility.detail.select = this.$units.select;
        this.$customEventVisibility.detail.visibility = status;
        this.$el.dispatchEvent(this.$customEventVisibility);

    }


    output_clear() {

        this.$units.select.setAttribute('data-select-value', '');
        this.$units.select.classList.remove(this.$options.classNames.select.selected);
        this.$units.select.classList.add(this.$options.classNames.select.placehoder);
        this.$units.output.innerHTML = this.$options.placeholder.output;

    }

    
    added(children) {

        let elementOption = null;
        let elementOutputItem = null;

        let params = {
            selected: {
                value: [],
                output: [],
            } 
        };


        children.forEach((variable) => {

            elementOption = document.createElement('div');				
            elementOption.classList.add(this.$options.classNames.option.main);
            elementOption.setAttribute('role', 'option');
            elementOption.setAttribute('aria-selected', false);
            elementOption.setAttribute('data-option-value', variable.value);
            elementOption.innerHTML = variable.label || '';


            // added data-attrs
            if (variable.attrs !== undefined) {

                Object.entries(Object.assign({}, variable.attrs)).forEach((value) => {

                    elementOption.setAttribute(value[0], value[1]);

                })

            }


            // selected
            if (variable.selected == true && variable.disabled == undefined) {
    
                if (this.$options.type == 'multiple') {
                    
                    elementOutputItem = document.createElement('div');
                    elementOutputItem.classList.add(this.$options.classNames.output.item);
                    elementOutputItem.setAttribute('data-item-value', variable.value);
                    elementOutputItem.innerHTML = `
                        <span>${variable.label}</span>
                        <button 
                            type="button" 
                            class="${this.$options.classNames.output.clear}" 
                            aria-label="Delete selected"
                            title="Delete selected"
                        >X</button>
                    `;

                    params.selected.output.push(elementOutputItem.outerHTML);

                } else {

                    params.selected.output.push(variable.label);

                }

                elementOption.classList.add(this.$options.classNames.option.selected);
                elementOption.setAttribute('aria-selected', true);

                this.$units.select.classList.remove(this.$options.classNames.select.placehoder);
                this.$units.select.classList.add(this.$options.classNames.select.selected);
                
                params.selected.value.push(variable.value);
                this.$units.select.setAttribute('data-select-value', params.selected.value.join());
                this.$units.output.innerHTML = params.selected.output.join(' ');
                this.$options.value = params.selected.value.join();
                
            }

        
            // disabled
            if (variable.disabled == true) {

                elementOption.classList.add(this.$options.classNames.option.disabled);

            }


            this.$units.listbox.innerHTML += elementOption.outerHTML;

        })


        this.$units.options =  Array.from(this.$units.listbox.querySelectorAll(`.${this.$options.classNames.option.main}`));

        // selected
        if (this.$options.type == 'multiple') {

            this.$units.optionsSelected = Array.from(this.$units.select.querySelectorAll(`.${this.$options.classNames.option.selected}`)) || []

        } else {

            this.$units.optionsSelected = this.$units.select.querySelector(`.${this.$options.classNames.option.selected}`) || null;

        }

    }

};