/**
 * Basic UI: Readmore.
 */
const BasicReadmore = (() => {

	class Core {

	    constructor(element) {
	        
	        this.$el = element;
			this.$trigger = this.$el.nextSibling;			
			
			let dataOptions = JSON.parse(this.$el.getAttribute('data-readmore-options')) || {};
			let listInit = Array.from(document.querySelectorAll('.readmore-init')).concat(this.$el);
			
			this.$options = {
				index: listInit.indexOf(this.$el),
				trigger: {
					show: 	 dataOptions['textShow'] || 'Показать ещё',
					hide: 	 dataOptions['textHide'] || 'Скрыть',
					classes: dataOptions['classTrigger'] ? `readmore-trigger,${dataOptions['classTrigger']}`.split(',') : ['readmore-trigger']
				},
				className: {
					show: 	  'readmore--show',
					selected: 'readmore-trigger--selected'
				}
			};
						
			this.$customEvent = new CustomEvent('readmore switched', { 
				bubbles: true,
				detail:  {
					'readmore': null,
					'trigger': null,
					'hidden': true
				}
			});			
	        		    	
	    }


		init() {

			this.build();			

		}


		build() {

			this.$el.classList.add('readmore');
			this.$el.classList.add('readmore-init');
			this.$el.setAttribute('id', `readmore-id-${this.$options.index}`);


			// build trigger
			let triggerElement = document.createElement('button');
		
			triggerElement.setAttribute('title', 'More');
			triggerElement.setAttribute('aria-label', 'More');		
			triggerElement.setAttribute('aria-expanded', false);
			triggerElement.setAttribute('aria-controls', this.$el.getAttribute('id'));		
			triggerElement.innerHTML = this.$options.trigger.show;
					
			// add classes
			this.$options.trigger.classes.forEach((value) => triggerElement.classList.add(value));
	
			// init and add trigger
			if (this.$el.scrollHeight > this.$el.getBoundingClientRect().height) { 
				
				this.$el.classList.add('more');
				this.$el.parentElement.insertBefore(triggerElement, this.$el.nextSibling);
			
			}
			
			this.$trigger = triggerElement;
			this.$trigger.addEventListener('click', this.listener_readmore.bind(this), false);

		}


		listener_readmore() {
			
			// show
			if (this.$el.style.maxHeight == '') {

				this.method_show();
			
			// hide
			} else if (this.$el.style.maxHeight !== '') {

				this.method_hide();

			}


			// custom event: readmore switched
			this.$customEvent.detail.readmore = this.$el;
			this.$customEvent.detail.trigger = this.$trigger;
			this.$el.dispatchEvent(this.$customEvent);	
			
		}
		
		
		method_show() {
			
			this.$el.classList.add(this.$options.className.show);
			this.$el.style.maxHeight = `${this.$el.scrollHeight}px`;
						
			this.$trigger.setAttribute('aria-expanded', true);
			this.$trigger.innerHTML = this.$options.trigger.hide;
			this.$trigger.classList.add(this.$options.className.selected);

			this.$customEvent.detail.hidden = false;

		}


		method_hide() {
			
			this.$el.style.maxHeight = '';
			this.$el.classList.remove(this.$options.className.show);					
												
			this.$trigger.setAttribute('aria-expanded', false);
			this.$trigger.innerHTML = this.$options.trigger.show;
			this.$trigger.classList.remove(this.$options.className.selected);					
			
			this.$customEvent.detail.hidden = true;

		}	

	}


	let active = null;


	/**
	 * Readmore initialization
	 * @param {object|string} variable - element for initializing readmore 
	 */
	const init = (variable) => {

		let element = null,
			elements = BasicCore.variables(variable, '.js-readmore');
			

		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);

			elements.forEach((value) => {

				element = value;

				active = new Core(value);
				active.init();

			});

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Readmore init. \nMessage: ${error.message} \nElement: `, element);

		}

	};


	/**
	 * Method: Readmore show
	 * @param {object|string} variable - element show 
	 */
	const show = (variable) => {

		let element = (typeof variable == 'string') ? document.querySelector(variable) : variable;


		try {

			if (element == null) throw new Error(BasicCore.logging['error']['type']);
			
			active = new Core(element);
			active.method_show();

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Readmore show. \nMessage: ${error.message} \nElement:`, variable);

		}

	};	   
	

	/**
	 * Method: Readmore hide
	 * @param {object|string} variable - element hide 
	 */
	const hide = (variable) => {

		let element = (typeof variable == 'string') ? document.querySelector(variable) : variable;


		try {

			if (element == null) throw new Error(BasicCore.logging['error']['type']);
			
			active = new Core(element);
			active.method_hide();

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Readmore hide. \nMessage: ${error.message} \nElement:`, variable);

		}

	};	   


	return { init, show, hide };

})()


window.BasicReadmore = BasicReadmore;


export { BasicReadmore };