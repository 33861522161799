import { Core } from './core.js';

export class Default extends Core {

    init() {
        
        this.event();
        this.dropdown_position();
                
    }


    event() {

        this.$units.select.addEventListener('click',   this.listener_click.bind(this), false);
        this.$units.select.addEventListener('keydown', this.listener_keydown.bind(this), false);

    }


    listener_click(event) {

        let target = event.target,
            parent = {
                option: target.closest(`.${this.$options.classNames.option.main}`),
                placehoder: target.closest(`.${this.$options.classNames.option.placehoder}`),
            },
            result = {
                value: '',
                previous: null,
                selected: false,
            };



        // show/hide dropdown
        if (
            target.classList.contains(this.$options.classNames.clear)        == false && 
            target.classList.contains(this.$options.classNames.search.field) == false
        ) {

            this.dropdowwn_visibility();

        }


        // output clear or placeholder
        if (target.classList.contains(this.$options.classNames.clear) == true || parent.placehoder !== null) {
    
            this.output_clear();

            if (parent.placehoder !== null) {

                parent.placehoder.setAttribute('aria-selected', true);
                
            }
            
            if (this.$units.optionsSelected !== null) {
                
                result.previous = this.$units.optionsSelected;
                this.$units.optionsSelected.setAttribute('aria-selected', false);
                this.$units.optionsSelected.classList.remove(this.$options.classNames.option.selected);
                
            }            
            
            result.selected = false;
            result.value = this.$options.placeholder.output;
            
            // custom event: clear
            this.$customEventClear.detail.el = this.$el;
            this.$customEventClear.detail.select = this.$units.select;
            this.$customEventClear.detail.index = this.$options.index;
            this.$customEventClear.detail.value = result.value;
            this.$customEventClear.detail.previous = result.previous;
            this.$customEventClear.detail.selected = result.selected;                
            this.$el.dispatchEvent(this.$customEventClear);
        
        }


        // options
        if (parent.option !== null) {

            // no selected and placeholder
            if (
                target.closest(`.${this.$options.classNames.option.selected}`)   == null && 
                target.closest(`.${this.$options.classNames.option.placehoder}`) == null
            ) {

                result.selected = true;
                result.value = parent.option.getAttribute('data-option-value');
    
                this.$units.select.classList.add(this.$options.classNames.select.selected);
                this.$units.select.classList.remove(this.$options.classNames.select.placehoder);
                this.$units.select.setAttribute('data-select-value', result);
                this.$units.output.innerHTML = parent.option.innerHTML;
    
                if (this.$units.optionsSelected !== null) {
    
                    result.previous = this.$units.optionsSelected;
                    this.$units.optionsSelected.setAttribute('aria-selected', false);
                    this.$units.optionsSelected.classList.remove(this.$options.classNames.option.selected);
    
                }
    
                parent.option.setAttribute('aria-selected', true);
                parent.option.classList.add(this.$options.classNames.option.selected);            
                this.$units.optionsSelected = parent.option; 

            }

            
            // custom event: choise
            this.$customEventChoice.detail.el = this.$el;
            this.$customEventChoice.detail.select = this.$units.select;
            this.$customEventChoice.detail.index = this.$options.index;
            this.$customEventChoice.detail.value = result.value;
            this.$customEventChoice.detail.target = parent.option;
            this.$customEventChoice.detail.previous = result.previous;
            this.$customEventChoice.detail.selected = result.selected;                
            this.$el.dispatchEvent(this.$customEventChoice);

        }


        this.$el.innerHTML = `<option value="${result}">${result}</option>`

    }


    listener_keydown(event) {

        let code = event.code;
        let optionsShow = this.$units.options.filter((value) => value.classList.contains(this.$options.classNames.option.hide) == false);
        let variable = {
            prev: optionsShow[optionsShow.length - 1],
            next: optionsShow[0]
        },
        result = {
            value: '',
            previous: null,
        };
        

        // show/hide
        if (code == 'Space') {

            this.dropdowwn_visibility();
            event.preventDefault();
        
        }
        

        // selected
        if (this.$units.select.classList.contains(this.$options.classNames.select.notfound) == false) {

            if (code == 'ArrowUp' || code == 'ArrowDown') {
                
                optionsShow.find((value, index, array) => {
                    
                    if (value.classList.contains(this.$options.classNames.option.selected) == true) {
                        
                        variable.prev = array[index - 1] || array[array.length - 1];
                        variable.next = array[index + 1] || array[0];
                        
                    }
                    
                });


                if (this.$units.optionsSelected !== null) {

                    result.previous = this.$units.optionsSelected;
                    this.$units.optionsSelected.classList.remove(this.$options.classNames.option.selected);

                }


                if (code == 'ArrowUp')   this.$units.optionsSelected = variable.prev;
                if (code == 'ArrowDown') this.$units.optionsSelected = variable.next;					


                // placehoder or selected
                if (this.$units.optionsSelected.classList.contains(this.$options.classNames.option.placehoder) == true) {

                    this.$units.select.classList.add(this.$options.classNames.select.placehoder);
                    this.$units.select.classList.remove(this.$options.classNames.select.selected);

                } else {

                    this.$units.select.classList.add(this.$options.classNames.select.selected);
                    this.$units.select.classList.remove(this.$options.classNames.select.placehoder);

                }

                result.value = this.$units.optionsSelected.getAttribute('data-option-value');

                this.$units.select.setAttribute('data-select-value', result.value);
                this.$units.output.innerHTML = this.$units.optionsSelected.innerHTML;
                this.$units.optionsSelected.classList.add(this.$options.classNames.option.selected);
                
                event.preventDefault();
            
            }
        
        }


        // custom event: choise
        this.$customEventChoice.detail.el = this.$el;
        this.$customEventChoice.detail.select = this.$units.select;
        this.$customEventChoice.detail.index = this.$options.index;
        this.$customEventChoice.detail.value = result.value;
        this.$customEventChoice.detail.target = this.$units.optionsSelected;
        this.$customEventChoice.detail.previous = result.previous;
        this.$customEventChoice.detail.selected = !this.$units.optionsSelected.classList.contains(this.$options.classNames.option.placehoder);                
        this.$el.dispatchEvent(this.$customEventChoice);

    }
};