/**
 * Basic UI: Class transfer.
 */
const BasicClassTransfer = (() => {

	class Core {

	    constructor(element) {
	        
	        this.$el = element;	     
			this.$children = Array.from(this.$el.children);

			this.$options = {
				className: {
					main:	  'class-transfer',
					item:	  'class-transfer-item',
					selected: 'class-transfer--selected' 
				}
			};			
			
			this.$customEventChoice = new CustomEvent('classTransfer choice', { 
				bubbles: true,
				detail:  {
					'index': null,
					'target': null,
					'previous': false,
					'selected': false
				}
			});
							        		    	
	    }


		init() {

			this.build();

		}


		build() {

			this.$children.forEach((value) => {

				value.setAttribute('role', 'listitem');

				if (value.getAttribute('aria-selected') == 'true') {

					value.classList.add(this.$options.className.selected);

				} else {

					value.setAttribute('aria-selected', false);

				}

			})

			this.$el.setAttribute('role', 'listbox');
			this.$el.classList.add(this.$options.className.main);
			this.$el.classList.add('class-transfer-init');
			this.$el.addEventListener('click', this.listener.bind(this), false);

		}


	    listener(event) {
		
			let target = event.target,
				current = target.closest(`.${this.$options.className.item}`) || false,
				previous = this.$el.querySelector(`.${this.$options.className.item}.${this.$options.className.selected}`) || false;

			
			if (current !== false) {

				current.classList.toggle(this.$options.className.selected);
				current.setAttribute('aria-selected', current.getAttribute('aria-selected') == 'true');

				// remove selected
				if (previous !== false) {

					previous.setAttribute('aria-selected', false);				
					previous.classList.remove(this.$options.className.selected);
				
				}
				
				// custom event: classTransfer choice
				this.$customEventChoice.detail.target = current;
				this.$customEventChoice.detail.previous = previous;
				this.$customEventChoice.detail.index = this.$children.indexOf(current);
				this.$customEventChoice.detail.selected = (current.classList.contains(this.$options.className.selected) == true) ? true : false;
				this.$el.dispatchEvent(this.$customEventChoice);

			}
		
		}

	}


	let active = null;


	/**
	 * Class transfer initialization
	 * @param {object|string} variable - element for initializing class-transfer 
	 */
	const init = (variable) => {

		let element = null,
			elements = BasicCore.variables(variable, '.js-class-transfer:not(.class-transfer-init)');

			
		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
					
			elements.forEach((value) => {

				element = value;
				
				active = new Core(value);				
				active.init();

			});

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Class Transfer init. \nMessage: ${error.message} \nElement: `, element);

		}		

	}   


	return { init };

})()


window.BasicClassTransfer = BasicClassTransfer;


export { BasicClassTransfer };