/**
 * Basic UI: Accordion.
 */
const BasicAccordion = (() => {

	class Core {

	    constructor(element) {

			let listInit = Array.from(document.querySelectorAll('.accordion-init'));
			listInit.push(this.$el);								
				
			this.$el = element;			      	
        	this.$children = Array.from(this.$el.children);

			this.$options = {
				index: listInit.indexOf(this.$el),
				offHide: (this.$el.getAttribute('data-accordion-off-hide') == 'true') ? true : false,
				className: {
					trigger:  'accordion-trigger',
					content:  'accordion-content',
					selected: 'accordion--selected'
				}
			}

			this.$customEventChoice = new CustomEvent('accordion choice', { 
				bubbles: true,
				detail:  {
					'index': null,
					'accordion': null,
					'previous': null
				}
			});
	        		    	
	    }


		init() {				
						
			this.build();
        	this.event();

		}


	    build() {
												
			// build accordion children
			this.$children.forEach((value, index) => {

				let idContent = `accordion-${this.$options.index}-content-${index}`;
				let trigger = value.querySelector(`.${this.$options.className.trigger}`);
				let content = value.querySelector(`.${this.$options.className.content}`);


				// selected
				if (trigger.getAttribute('aria-expanded') == 'true') {
					
					content.style.maxHeight = '';
					content.style.overflow = '';					
					trigger.setAttribute('aria-disabled', true);
					value.classList.add(this.$options.className.selected);

				} else {
					
					content.style.maxHeight = '0px';
					content.style.overflow = 'hidden';
					trigger.setAttribute('aria-expanded', false);
				
				}
									
				content.setAttribute('id', idContent);
				content.setAttribute('role', 'region');
				trigger.setAttribute('aria-controls', idContent);

			});


			this.$el.classList.add('accordion');
			this.$el.classList.add('accordion-init');
			this.$el.setAttribute('data-accordion-id', this.$options.index);
				
	    }


	    event() {

			if (this.$options.offHide == false) {

				this.$el.addEventListener('click', this.listener_default.bind(this), false);

			} else {

				this.$el.addEventListener('click', this.listener_off_close.bind(this), false);
			
			}			

	    }


	    listener_default(event) {
		
			let target = event.target;			
			let selected = {
				current: target.parentElement,
				previous: this.$children.find((value) => value.classList.contains(this.$options.className.selected) == true)
			};
			
			let current = {
				parent:  selected.current,
				index: 	 this.$children.indexOf(selected.current),
				trigger: target.closest(`.${this.$options.className.trigger}`) || false,
				content: selected.current.querySelector(`.${this.$options.className.content}`)
			};
			
			let previous = {
				parent:  selected.previous || false,
				trigger: selected.previous.querySelector(`.${this.$options.className.trigger}`),
				content: selected.previous.querySelector(`.${this.$options.className.content}`)
			};
				
			
			// switch accordion
			if (current.parent.classList.contains(this.$options.className.selected) == false && current.trigger !== false) {	

				// previous selected accordion
				if (previous.parent !== false) {
																											
					previous.trigger.removeAttribute('aria-disabled');
					previous.trigger.setAttribute('aria-expanded', false);												
					previous.content.style.maxHeight = `${previous.content.scrollHeight}px`;

					setTimeout(() => {

						previous.content.style.maxHeight = '0px';
						previous.content.style.overflow = 'hidden';
						previous.parent.classList.remove(this.$options.className.selected);					
					
					}, 120);												

				}

				
				// current accordion
				current.parent.classList.add(this.$options.className.selected);					
				current.trigger.setAttribute('aria-disabled', true);				
				current.trigger.setAttribute('aria-expanded', true);
				current.content.style.overflow = '';					
				current.content.style.maxHeight = `${current.content.scrollHeight}px`;
				setTimeout(() => current.content.style.maxHeight = '', 120);


				// custom event: accordion choice
				this.$customEventChoice.detail.index = current.index;
				this.$customEventChoice.detail.previous = previous.parent;
				this.$customEventChoice.detail.accordion = current.parent;
				this.$el.dispatchEvent(this.$customEventChoice); 	
			
			}
		
		}


		listener_off_close(event) {		
		
			let status = false;
			let target = event.target;				
			let current = {
				parent:  target.parentElement,
				index: 	 this.$children.indexOf(target.parentElement),
				trigger: target.closest(`.${this.$options.className.trigger}`) || false,
				content: target.parentElement.querySelector(`.${this.$options.className.content}`)
			};

							
			if (current.trigger !== false) {
									
				current.parent.classList.toggle(this.$options.className.selected);
				current.content.style.maxHeight = `${current.content.scrollHeight}px`;				
				
				status = current.parent.classList.contains(this.$options.className.selected);				
				current.trigger.setAttribute('aria-expanded', status);

				setTimeout(() => {

					current.content.style.maxHeight = (status == false) ? '0px' : '';
					current.content.style.overflow =  (status == false) ? 'hidden' : '';				
				
				}, 120);
																	
				// custom event: accordion choice
				this.$customEventChoice.detail.index = current.index;
				this.$customEventChoice.detail.accordion = current.parent;
				this.$el.dispatchEvent(this.$customEventChoice); 	

			}
		
		}	

	}


	let active = null;


	/**
	 * Accordion initialization
	 * @param {Object|string} variable - element for initializing accordion 
	 */
	const init = (variable) => {
		
		let element = null,
			elements = BasicCore.variables(variable, '.js-accordion:not(.accordion-init)');

			
		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
						
			elements.forEach((value) => {

				element = value;
				
				active = new Core(value)
				active.init();

			});
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Accordion init. \nMessage: ${error.message} \nElement: `, element);

		}

	}   


	return { init };

})()


window.BasicAccordion = BasicAccordion;


export { BasicAccordion };