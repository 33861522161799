import { Default }  from './modules/default.js';
import { Multiple } from './modules/multiple.js';

const BasicSelectInstances = [];
const BasicSelect = (() => {
	
	let active = {};

	/**
	 * Select initialization
	 * @param {Object|string} variable - element for initializing select 
	 */
	 const init = (variable) => {
		
		let element = null,
			elements = BasicCore.variables(variable, '.js-select:not(.select-init)');

			
		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
						
			elements.forEach((value) => {

				element = value;

				active = (value.getAttribute('multiple') !== null) ? new Multiple(value) : new Default(value);
				active.init();

				BasicSelectInstances.push({
					$el:       active.$el,
					$children: active.$children,
					$options:  active.$options,
					$units:    active.$units
				})

			});
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Select init. \nMessage: ${error.message} \nElement: `, element);

		}
	
	}


	return { init };

})()


window.BasicSelect = BasicSelect;


export  { BasicSelect };