import { Core } from './core.js';

export class Multiple extends Core {

    init() {

        this.event();

    }


    event() {

        this.$units.select.addEventListener('click',   this.listener_click.bind(this), false);
        this.$units.select.addEventListener('keydown', this.listener_keydown.bind(this), false);

    }

    
    listener_click(event) {

        let target = event.target;        
        let parent = {
                option:     target.closest(`.${this.$options.classNames.option.main}`),
                selected:   target.closest(`.${this.$options.classNames.option.selected}`),
                placehoder: target.closest(`.${this.$options.classNames.option.placehoder}`),
            };


        // show/hide dropdown
        if (
            target.classList.contains(this.$options.classNames.clear)        == false && 
            target.classList.contains(this.$options.classNames.search.field) == false &&
            target.classList.contains(this.$options.classNames.output.clear) == false
        ) {

            this.dropdowwn_visibility();

        }


        // output clear or placeholder
        if (target.classList.contains(this.$options.classNames.clear) == true || parent.placehoder !== null) {
            
            this.output_clear();

            if (parent.placehoder !== null) {

                parent.placehoder.setAttribute('aria-selected', true);

            }

            // remove selected
            if (this.$units.optionsSelected !== null) {

                this.$units.optionsSelected.forEach(value => {
                    
                    value.setAttribute('aria-selected', false);
                    value.classList.remove(this.$options.classNames.option.selected);                    
                
                });

                this.$units.optionsSelected = [];

            }

        }


        // options
        if (parent.option !== null && parent.selected == null && parent.placehoder == null) {
            
            let elementOutputItem = document.createElement('div');
            elementOutputItem.classList.add(this.$options.classNames.output.item);
            elementOutputItem.setAttribute('data-item-value', parent.option.getAttribute('data-option-value'));
            elementOutputItem.innerHTML = `
                <span>${parent.option.innerHTML}</span>
                <button 
                    type="button" 
                    class="${this.$options.classNames.output.clear}" 
                    aria-label="Delete selected"
                    title="Delete selected"
                >X</button>
            `;

            parent.option.setAttribute('aria-selected', true);
            parent.option.classList.add(this.$options.classNames.option.selected);

            if (this.$units.select.classList.contains(this.$options.classNames.select.placehoder) == true) {

                this.$units.output.innerHTML = '';

            }

            this.$units.optionsSelected.push(parent.option);
            this.$units.select.classList.add(this.$options.classNames.select.selected);
            this.$units.select.classList.remove(this.$options.classNames.select.placehoder);
            this.$units.select.setAttribute('data-select-value', parent.option.getAttribute('data-option-value'));
            this.$units.output.innerHTML += elementOutputItem.outerHTML;

        }
        
        
        // delete selected
        if (target.classList.contains(this.$options.classNames.output.clear) == true) {

            let parentItem = target.parentElement;
            let placehoder = this.$units.select.querySelector(`.${this.$options.classNames.option.placehoder}`);


            this.$units.optionsSelected = this.$units.optionsSelected.filter((value) => {

                if (value.getAttribute('data-option-value') == parentItem.getAttribute('data-item-value')) {

                    value.setAttribute('aria-selected', false);
                    value.classList.remove(this.$options.classNames.option.selected)

                } else {

                    return value;
                    
                }

            });

            parentItem.remove();
            
            if (this.$units.optionsSelected.length == 0) {

                this.output_clear();
                if (placehoder !== null) placehoder.setAttribute('aria-selected', true);

            }                        

        }

    }


    listener_keydown(event) {

        let code = event.code;
        let optionsShow = this.$units.options.filter((value) => value.classList.contains(this.$options.classNames.option.hide) == false);
        let variable = {
            prev: optionsShow[optionsShow.length - 1],
            next: optionsShow[0]
        };
        

        // show/hide
        if (code == 'Space') {

            this.dropdowwn_visibility();
            event.preventDefault();
        
        }
        

        // selected
        if (this.$units.select.classList.contains(this.$options.classNames.select.notfound) == false) {

            if (code == 'ArrowUp' || code == 'ArrowDown') {
                
                optionsShow.find((value, index, array) => {
                    
                    if (value.classList.contains(this.$options.classNames.option.selected) == true) {
                        
                        variable.prev = array[index - 1] || array[array.length - 1];
                        variable.next = array[index + 1] || array[0];
                        
                    }
                    
                });


                if (code == 'ArrowUp')   this.$units.optionsSelected = variable.prev;
                if (code == 'ArrowDown') this.$units.optionsSelected = variable.next;					


                // placehoder or selected
                if (this.$units.optionsSelected.classList.contains(this.$options.classNames.option.placehoder) == true) {

                    this.$units.select.classList.add(this.$options.classNames.select.placehoder);
                    this.$units.select.classList.remove(this.$options.classNames.select.selected);

                } else {

                    this.$units.select.classList.add(this.$options.classNames.select.selected);
                    this.$units.select.classList.remove(this.$options.classNames.select.placehoder);

                }


                this.$units.output.innerHTML = this.$units.optionsSelected.innerHTML;
                this.$units.optionsSelected.classList.add(this.$options.classNames.option.selected);
                this.$units.select.setAttribute('data-select-value', this.$units.optionsSelected.getAttribute('data-option-value'));
                
                event.preventDefault();
            
            }
        
        }

    }

}