import AirDatepicker from "./air-datepicker/datepicker";

/**
 * Basic UI: Datepicker.
 */
const BasicDatepickerInitArray = [];
const BasicDatepicker = (() => {

	class Core {

	    constructor(element, options) {
	        
	        this.$el = element;
			this.$options = options || {};				
			
			this.build();

	    }


		build() {

			if (this.$el.classList.contains('datepicker-init') == true) {

				BasicDatepickerInitArray.find(variable => {

					if (variable.el === this.$el) this.$airDatepicker =  variable.airDatepicker;

				});
			
			}
			

			if (this.$el.classList.contains('datepicker-init') == false) {

				this.$el.classList.add('datepicker-init');
				this.$airDatepicker = new AirDatepicker(this.$el, this.$options);
				
				BasicDatepickerInitArray.push({
					el: this.$el,
					airDatepicker: this.$airDatepicker
				});
			
			}

		}


		on({name, listener}) {

			this.$options[name] = listener;
			this.$airDatepicker.update(this.$options);

		}


		methods({name, options}) {

			let values = Object.values(options);

			this.$airDatepicker[name](...values);			
			if (name == 'destroy') this.$el.classList.remove('datepicker-init');

		}

	}


	let active = null;


	/**
	 * Datepicker initialization
	 * @param {object|string} variable - element for initializing Datepicker,
	 * @param {object} 		  config   - configuration for initializing Datepicker
	 */
	const init = (variable, config) => {

		let options = config || {},
			element = null,
			elements = BasicCore.variables(variable, '.js-datepicker:not(.datepicker-init)');
		

		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);

			elements.forEach((value) => {

				element = value;
				options = JSON.parse(element.getAttribute('data-datepicker-options')) || options;

				active = new Core(value, options);				

			});

			return active;

		} catch(error) {

			console.error(`${BasicCore.logging['name']} Datepicker init. \nMessage: ${error.message} \nElement: `, element);

		}	

	};


	/**
	 * Datepicker events
	 * @param {object|string} variable 		  - element for event Datepicker;
	 * @param {string} 		  config.name     - name event Datepicker;
	 * @param {function} 	  config.listener - listener for event Datepicker.
	 */
	const on = (variable, config) => {

		let element = (typeof variable == 'string') ? document.querySelector(variable) : variable,
			options = {
				name: config.name || 'onSelect',
				listener: config.listener || function({date, formattedDate, datepicker}) {}  
			};
		
		
		try {

			active = new Core(element);	
			active.on(options);

			return active;

		} catch(error) {

			console.error(`${BasicCore.logging['name']} Datepicker on \nMessage: ${error.message} \nElement:`, variable);

		}

	}


	/**
	 * Datepicker methods
	 * @param {object|string} variable      - element for methods Datepicker;
	 * @param {string} 		  config.name   - name methods Datepicker;
	 * @param {object} 		  config.config - config for method Datepicker.
	 */
	const methods = (variable, config) => {

		let element = (typeof variable == 'string') ? document.querySelector(variable) : variable,
			params = {
				name: config.name || '',
				options: config.config || {}
			};
				
		
		try {

			active = new Core(element);	
			active.methods(params);

			return active;

		} catch(error) {

			console.error(`${BasicCore.logging['name']} Datepicker methods \nMessage: ${error.message} \nElement:`, variable);

		}

	}


	return { init, on, methods };

})()
	

window.BasicDatepicker = BasicDatepicker;


export  { BasicDatepicker };