// libs https://imask.js.org/
import './imask.min.js'


/**
 * Basic UI: Mask.
 */
const BasicMask = (() => {

    class Core {

        constructor(element, options) {

            this.$el = element;            
                        
            let dataMaskOptions = JSON.parse(this.$el.getAttribute('data-mask-options')) || {};
                        
            this.$options = options || {
                'type':      dataMaskOptions['type']      || 'default',
                'pattern':   dataMaskOptions['pattern']   || false,
                'max':       dataMaskOptions['max']       || false,
                'min':       dataMaskOptions['min']       || false,
                'separator': dataMaskOptions['separator'] || ' ', 
                'lazy':      dataMaskOptions['lazy'] !== undefined ? dataMaskOptions['lazy'] : true
            };

        }


        init() {

            this.build();            

        }


        build() {

            let maskOptions = {},
                maskMax = null,
                maskMin = null,
                maskPattern = null;


            // default
            if (this.$options['type'] == 'default' || this.$options['type'] == 'phone') {

                maskPattern = this.$options['pattern'] || '+{7}(000)000-00-00';
                maskOptions = {
                    mask: maskPattern,
                    lazy: this.$options['lazy']
                }

            }
            
            
            // date
            if (this.$options['type'] == 'date') {

                maskPattern = this.$options['pattern'] || 'd{.}`m{.}`Y'; // default DD.MM.YYYY
                maskMin = this.$options['min'] ? new Date(this.$options['min']) : new Date(1900, 0, 1);
                maskMax = this.$options['max'] ? new Date(this.$options['max']) : new Date(9999, 0, 1);
                maskOptions = {
                    mask: Date,
                    pattern: maskPattern,
                    min: maskMin,
                    max: maskMax,
                    lazy: this.$options['lazy']
                };

            }


            // number or currency
            if (this.$options['type'] == 'number' || this.$options['type'] == 'currency') {

                maskMin = this.$options['min'] ? Number.parseInt(this.$options['min']) : 0;
                maskMax = this.$options['max'] ? Number.parseInt(this.$options['max']) : 1000000000;
                
                // number
                if (this.$options['type'] == 'number') {

                    maskPattern = this.$options['pattern'] || Number;
                    maskOptions = {
                        mask: maskPattern,
                        min: maskMin,
                        max: maskMax,
                        lazy: this.$options['lazy'],
                        thousandsSeparator: this.$options['separator']
                    };

                }

                // currency
                if (this.$options['type'] == 'currency') {

                    maskPattern = this.$options['pattern'] || 'num ₽';
                    maskOptions = {
                        mask: maskPattern,
                        blocks: {
                            num: {
                                mask: Number,
                                max: maskMax,
                                lazy: this.$options['lazy'],
                                thousandsSeparator: this.$options['separator']
                            }
                        }
                    };

                }

            }


            // init IMask
            this.$iMask = new IMask(this.$el, maskOptions);
            this.$el.classList.add('mask-init');

        }


        method_options() {

        	this.$iMask = new IMask(this.$el, this.$options);
            this.$el.classList.add('mask-init');

        }


        update_options(options) {

            let updateOptions = options || this.$options;

            this.$iMask.updateOptions(updateOptions);

        }

    }


    let active = null;


	/**
	 * Mask initialization
	 * @param {Object|string} variable - element for initializing mask
	 */
    const init = (variable) => {

        let element = null,
            elements = BasicCore.variables(variable, '.js-mask:not(.mask-init)');

            
        try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            
            elements.forEach((value) => {
                
                element = value;

                active = new Core(value);
                active.init();

            });

            return active;
        
        } catch(error) {

			console.error(`${BasicCore.logging['name']} Mask init. \nMessage: ${error.message} \nElement: `, element);

		}

    }    


	/**
	 * Method: Initialization with parameters 
	 * @param {Object|string} variable - element for initializing mask;
	 * @param {Object}        config   - element parameters.
	 */
    const options = (variable, config) => {

        let options = config || false,
            element = null,
            elements = BasicCore.variables(variable, '.js-mask');

            
        try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            
            elements.forEach((value) => {
                
                element = value;

                active = new Core(value, options);
                active.method_options();

            });

            return active;
        
        } catch(error) {

			console.error(`${BasicCore.logging['name']} Mask options. \nMessage: ${error.message} \nElement:`, element);
            return false;

		}

    }


    return { init, options };

})()


window.BasicMask = BasicMask;


export { BasicMask };