// core
import './modules/core';

// polyfills
import './modules/polyfills/find';
import './modules/polyfills/array.from';
import './modules/polyfills/closest';
import './modules/polyfills/number.parseInt';
import './modules/polyfills/startsWith';
import './modules/polyfills/customEvent';

// ui
import { BasicAccordion } 			 from './modules/ui/accordions';
import { BasicPopup } 				 from './modules/ui/popup';
import { BasicReadmore } 			 from './modules/ui/readmore';
import { BasicTabs } 				 from './modules/ui/tabs';
import { BasicToggleVisibleContent } from './modules/ui/toggle-visible-content';
import { BasicClassTransfer }		 from './modules/ui/сlass-transfer';
import { BasicTooltip } 			 from './modules/ui/tooltip';
import { BasicMask } 				 from './modules/ui/mask/mask';
import { BasicSlider } 				 from './modules/ui/slider/slider';
import { BasicLazyLoadingImg } 		 from './modules/ui/lazy-loading-img';
import { BasicDropdown } 		     from './modules/ui/dropdown';
import { BasicSlideout } 		     from './modules/ui/slideout';

// form controls
import { BasicSelect }     		 from './modules/ui/form-controls/select/select';
import { BasicCheckbox }   		 from './modules/ui/form-controls/checkbox';
import { BasicDatepicker } 		 from './modules/ui/form-controls/datepicker/datepicker';
import { BasicQuantitySelector } from './modules/ui/form-controls/quantity-selector';

// init
document.addEventListener('DOMContentLoaded', function() {
	
	// console.time('BasicUI');	
	BasicAccordion.init();
	BasicPopup.init();
	BasicReadmore.init();
	BasicTabs.init();
	BasicToggleVisibleContent.init();
	BasicClassTransfer.init();
	BasicTooltip.init();
	BasicMask.init();
	BasicSlider.init();
	BasicLazyLoadingImg.init();	
	BasicDropdown.init();
	BasicSlideout.init();


	// form-controls
	BasicSelect.init();
	BasicCheckbox.init();
	BasicDatepicker.init();
	BasicQuantitySelector.init();
	// console.timeEnd('BasicUI');	

});